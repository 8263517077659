import React from "react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";

export default function InputComponent({
  label,
  placeholder,
  type,
  register,
  value,
  onChange,
  isDisabled,
}) {
  return (
    <FormControl>
      <FormLabel color="#8A9099" fontSize="14px" fontWeight="normal">
        {label}
      </FormLabel>
      <Input
        size="md"
        placeholder={placeholder}
        type={type ? type : "text"}
        variant="outline"
        borderRadius="0.75rem"
        focusBorderColor="brand.900"
        value={value}
        onChange={onChange}
        isDisabled={isDisabled ? isDisabled : false}
        {...register}
      />
    </FormControl>
  );
}
