import React from "react";
import logo from "../../images/ehcc_logo_colour 1.png";
import { useNavigate } from "react-router-dom";
import dashboardImg from "../../images/Grid.png";
import reportImg from "../../images/ClipboardText.png";
import group from "../../images/UsersThree.png";
import userImg from "../../images/User.png";
import logoutImg from "../../images/logout.png";
import { Box, Flex, useToast } from "@chakra-ui/react";
import ImageComponent from "../ui/Image";
import NavItem from "../ui/NavItem";

import { logout } from "../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../util/authContext";

export default function DashboardSide({
  dashboard,
  report,
  user,
  profile,
  show = false,
}) {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const toast = useToast();

  const ctx = useContext(AuthContext);

  async function logoutHandler() {
    try {
      await logout(token);
      ctx.logout();
      navigate("/login");
    } catch (error) {
      // console.log(error);
      navigate("/login");
    }
  }

  return (
    <Flex
      w="17rem"
      border="1px solid #ccc"
      flexDirection="column"
      h="130vh"
      py="2rem"
      gap="3rem"
      display={{ base: "none", md: "none", lg: "flex" }}
    >
      <Box w="9.75rem" mx="auto">
        <ImageComponent src={logo} alt="dashboard" height="7.69rem" />
      </Box>
      <div className="w-full flex flex-col">
        <NavItem
          src={dashboardImg}
          alt="dashboard"
          height="16px"
          path=""
          title="dashboard"
        />
        <NavItem
          src={reportImg}
          alt="report"
          height="17.25px"
          path="report"
          title="report"
        />

        <NavItem
          height="17.25px"
          src={group}
          alt="group"
          path="user"
          title="user"
        />

        <NavItem
          src={userImg}
          alt="user"
          height="17.25px"
          title="profile"
          path="profile"
        />
      </div>
      <div onClick={logoutHandler} className="mt-20">
        <button className="flex items-center gap-4 px-6 py-5 text-[15px] text-[#FF0000] capitalize font-medium">
          <Box w="15px">
            <ImageComponent src={logoutImg} alt="logout" height="17.25px" />
          </Box>
          logout
        </button>
      </div>
    </Flex>
  );
}
