import React, { Fragment, useState } from "react";
import DashboardReusable from "../../components/ui/DashboardReusable";
import ReusableSubHeader from "../../components/ui/ReusableSubheader";
import DirectorateReportContainer from "./DirectorateReportContainer";
import Loader from "../../components/ui/Loader";

export default function Report() {
  const [progress, setProgress] = useState(0);
  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable show={true}>
        <ReusableSubHeader text="Reports" />
        <DirectorateReportContainer
          progress={progress}
          setProgress={setProgress}
        />
      </DashboardReusable>
    </Fragment>
  );
}
