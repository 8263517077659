import React, { Fragment } from "react";
import DashboardReusable from "../../components/ui/DashboardReusable";
import ReusableSubHeader from "../../components/ui/ReusableSubheader";
import DashboardReport from "./DashboardReport";
import { Box } from "@chakra-ui/react";
import DashboardSubmission from "./DashboardSubmission";
import { useEffect } from "react";
import { getPastorDashboard } from "../../util/http";
import { useState } from "react";
import Loader from "../../components/ui/Loader";

export default function DirectorateDashboard() {
  const [reports, setReports] = useState("");
  const [allReports, setAllReports] = useState([]);
  const [progress, setProgress] = useState(0);
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getPastorDashboard(token);
        console.log(response);
        setProgress(80);
        setProgress(100);
        setAllReports(response?.reports?.reverse());
        setReports(response);
      } catch (error) {
        setProgress(100);
        // console.log(error);
      }
    }
    fetchData();
  }, [token, role]);

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable
        dashboard="directorate-dashboard"
        report="directorate-report"
        user="directorate-user"
        profile="directorate-profile"
        show={true}
        path="directorate-profile"
      >
        <ReusableSubHeader text="Dashboard" />
        <Box mt="1.5rem">
          <DashboardReport report={reports} />
        </Box>
        <DashboardSubmission
          reports={allReports}
          setAllReports={setAllReports}
        />
      </DashboardReusable>
    </Fragment>
  );
}
