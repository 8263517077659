import React, { Fragment } from "react";

export default function ReusableSelect({
  register,
  register1,
  label,
  label1,
  options,
  options1,
  onChange,
  onChange1,
  disabled,
}) {
  return (
    <div className="input-wrapper">
      <div className="input-container">
        <label
          style={{
            fontSize: "14px",
            fontWeight: "normal",
            textTransform: "capitalize",
            color: "#8A9099",
          }}
        >
          {label ? label : "Date"}
        </label>
        <select
          style={{ textTransform: "capitalize" }}
          onChange={onChange}
          {...register}
          className="input text-base capitalize"
          disabled={disabled ? disabled : false}
        >
          <option value="">please select</option>
          <Fragment>
            {options.map((el) => (
              <option style={{ textTransform: "capitalize" }} value={el}>
                {el}
              </option>
            ))}
          </Fragment>
        </select>
      </div>
      <div className="input-container">
        <label
          style={{
            fontSize: "14px",
            fontWeight: "normal",
            textTransform: "capitalize",
            color: "#8A9099",
          }}
        >
          {label1 ? label1 : "Service"}
        </label>
        <select
          style={{ textTransform: "capitalize" }}
          onChange={onChange1}
          {...register1}
          className="input text-base capitalize"
        >
          <option style={{ textTransform: "capitalize" }} value="">
            please select
          </option>
          <Fragment>
            {options1.map((el) => (
              <option value={el}>{el}</option>
            ))}
          </Fragment>
        </select>
      </div>
    </div>
  );
}
