import React, { Fragment, useEffect, useState } from "react";
import DashboardSide from "../dashboard/DashboardSide";
import Header from "../dashboard/Header";

import { Flex } from "@chakra-ui/react";
import { getUsers } from "../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../util/authContext";

export default function DashboardReusable({
  children,
  title,
  dashboard,
  report,
  user,
  profile,
  show,
  path,
}) {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const userId = localStorage.getItem("memberID");
  const [userData, setUser] = useState("");

  const ctx = useContext(AuthContext);

  useEffect(() => {
    setUser(ctx.user);
  }, [ctx.user]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       if (role?.toLowerCase() !== "member") {
  //         const response = await getUsers(token, userId);
  //         setUser(response?.user[0]);
  //         return;
  //       } else {
  //         setUser([]);
  //         return;
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   fetchData();
  // }, [token, userId]);

  return (
    <Flex w="100%" alignItems="center">
      <DashboardSide
        dashboard={dashboard}
        user={user}
        report={report}
        profile={profile}
        show={show}
      />
      <Fragment>
        <div className="flex-1 bg-[#F6F6F6] h-full lg:h-[130vh]">
          <Header path={path} user={userData} title={title} />
          {children}
        </div>
      </Fragment>
    </Flex>
  );
}
