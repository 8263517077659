import React from "react";
import {
  Input,
  InputGroup,
  InputLeftAddon,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Button,
  MenuItem,
} from "@chakra-ui/react";
import { SearchIcon, ChevronDownIcon } from "@chakra-ui/icons";

export default function ReusableReportFilter({
  searchHandler,
  filterApproved,
  filterPending,
  filterQueried,
}) {
  return (
    <Flex align="center" justify="space-between">
      <InputGroup
        w="90%"
        size="md"
        border="1px solid #ccc"
        borderRadius="0.75rem"
      >
        <InputLeftAddon
          border="none"
          bg="transparent"
          children={<SearchIcon />}
        />
        <Input
          variant="unstyled"
          border="none"
          type="search"
          placeholder="Search report..."
          onChange={searchHandler}
        />
      </InputGroup>
      <Menu>
        <MenuButton
          bg="white"
          border="1px solid #ccc"
          borderRadius="0.75rem"
          as={Button}
          rightIcon={<ChevronDownIcon />}
          // _focus={{ bg: "#fff" }}
          _hover={{ bg: "#fff" }}
          _expanded={{ bg: "#fff" }}
        >
          Filter
        </MenuButton>
        <MenuList
        // _focus={{ bg: "#fff" }}
        // _hover={{ bg: "#fff" }}
        // _expanded={{ bg: "#fff" }}
        >
          <MenuItem
            _focus={{ bg: "#fff" }}
            _hover={{ bg: "#fff" }}
            _expanded={{ bg: "#fff" }}
            onClick={filterPending}
          >
            Pending
          </MenuItem>
          <MenuItem
            _focus={{ bg: "#fff" }}
            _hover={{ bg: "#fff" }}
            _expanded={{ bg: "#fff" }}
            onClick={filterQueried}
          >
            Queried
          </MenuItem>
          <MenuItem
            _focus={{ bg: "#fff" }}
            _hover={{ bg: "#fff" }}
            _expanded={{ bg: "#fff" }}
            onClick={filterApproved}
          >
            Approved
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}
