import { Flex, useToast } from "@chakra-ui/react";
import React, { Fragment, useState, useEffect } from "react";
import DoubleInputState from "../../components/ui/DoubleInputState";
import ReusableComment from "../../components/ui/ReusableComment";
import ReusableTextarea from "../../components/ui/ReusableTextarea";

import { addComment, getUsers } from "../../util/http";
import Comments from "../../components/report-detail/Comments";
import ReportActions from "./ReportActions";

export default function DirectorateDetail({ report }) {
  const toast = useToast();

  function showToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status,
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  }

  const token = localStorage.getItem("token");
  const id = localStorage.getItem("memberID");
  const [user, setUser] = useState("");
  const [comment, setComment] = useState("");

  let thoseSickReport,
    obtainedPermissionReport,
    notObtainedPermissionReport,
    reasonsReport;

  if (!report) {
    thoseSickReport = [];
    obtainedPermissionReport = [];
    notObtainedPermissionReport = [];
    reasonsReport = [];
  } else {
    thoseSickReport = JSON.parse(report?.those_sick);
    obtainedPermissionReport = JSON.parse(report?.obtained_permission);
    notObtainedPermissionReport = JSON.parse(report?.not_obtain_permission);
    reasonsReport =
      report?.reasons.length !== 0 ? JSON.parse(report?.reasons) : [];
  }

  async function createCommentHandler() {
    const data = {
      type: user?.user_role,
      report_id: report?.id,
      body: comment,
    };

    try {
      const response = await addComment(token, data);
      showToast("Reply added", "", "success");
   
    } catch (error) {
      showToast(
        error?.response?.data.message || "something went wrong",
        "",
        "error"
      );
      // console.log(error);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getUsers(token, id);
        setUser(response?.user[0]);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [id, token]);

  return (
    <Fragment>
      <Flex direction="column" gap="2rem">
        <DoubleInputState
          label1="Date"
          label2="Service"
          placeholder=""
          value1={report?.report_date || ""}
          value2={report?.service || ""}
        />
        <DoubleInputState
          label1="No. of Department Members"
          label2="No. of Members in Service"
          placeholder="e.g 3"
          value1={report?.department_members || ""}
          value2={report?.members_in_service || ""}
        />

        <Fragment>
          {obtainedPermissionReport?.length === 0 ? (
            <DoubleInputState
              label1="NAME AND NUMBER OF THOSE WHO OBTAINED PERMISSION"
              label2=""
              placeholder="Full Name"
              placeholder2="Phone number"
            />
          ) : (
            <>
              {obtainedPermissionReport?.map((el) => (
                <DoubleInputState
                  label1="NAME AND NUMBER OF THOSE WHO OBTAINED PERMISSION"
                  label2=""
                  placeholder="Full Name"
                  placeholder2="Phone number"
                  value1={el.full_name}
                  value2={el.phone_number}
                />
              ))}
            </>
          )}
        </Fragment>

        <Fragment>
          {notObtainedPermissionReport?.length === 0 ? (
            <DoubleInputState
              label1="NAME AND NUMBER OF THOSE WHO DID NOT OBTAIN PERMISSION"
              label2=""
              placeholder="Full Name"
              placeholder2="Phone number"
            />
          ) : (
            <>
              {notObtainedPermissionReport?.map((el) => (
                <DoubleInputState
                  label1="NAME AND NUMBER OF THOSE WHO DID NOT OBTAIN PERMISSION"
                  label2=""
                  placeholder="Full Name"
                  placeholder2="Phone number"
                  value1={el.full_name}
                  value2={el.phone_number}
                />
              ))}
            </>
          )}
        </Fragment>

        <Fragment>
          {thoseSickReport?.length === 0 ? (
            <DoubleInputState
              label1="Those Sick, Travelled etc"
              label2=""
              placeholder="Full Name"
              placeholder2="Reason"
            />
          ) : (
            <>
              {thoseSickReport?.map((el) => (
                <DoubleInputState
                  label1="Those Sick, Travelled etc"
                  label2=""
                  placeholder="Full Name"
                  placeholder2="Reason"
                  value1={el.full_name}
                  value2={el.reason}
                />
              ))}
            </>
          )}
        </Fragment>

        <Fragment>
          {reasonsReport?.length === 0 ? (
            <DoubleInputState
              label1="REASONS FOR BEING ABSENT"
              label2=""
              placeholder="Full Name"
              placeholder2="Reason"
            />
          ) : (
            <>
              {reasonsReport?.map((el) => (
                <DoubleInputState
                  label1="REASONS FOR BEING ABSENT"
                  label2=""
                  placeholder="Full Name"
                  placeholder2="Reason"
                  value1={el.full_name}
                  value2={el.reason}
                />
              ))}
            </>
          )}
        </Fragment>
        <ReusableTextarea
          label="Points of Excellence"
          value={report?.excellence || ""}
        />

        <ReusableTextarea
          value={report?.need_improvement || ""}
          label="Needs for Improvement (NFIs)"
          bg="#E8E9EB"
        />

        <ReusableTextarea
          label="Any other concern or observations"
          value={report?.observation || ""}
        />

        <ReusableTextarea
          label="Assitant HOD’s remarks"
          value={report?.assistant_remark || ""}
        />

        <ReusableTextarea
          label="HOD’s remarks"
          value={report?.hod_remark || ""}
        />

        {report?.comments.length < 1 && (
          <Fragment>
            {user?.user_role?.toLowerCase() === "directorate" ? (
              <ReusableTextarea
                value={comment}
                readOnly={true}
                label="Director’s remarks"
                onChange={(e) => setComment(e.target.value)}
              />
            ) : (
              <ReusableTextarea
                value={comment}
                readOnly={true}
                label="official remarks - resident pastor"
                onChange={(e) => setComment(e.target.value)}
              />
            )}
          </Fragment>
        )}
      </Flex>
      <Comments report={report} user={user} />
      <ReportActions
        createCommentHandler={createCommentHandler}
        showToast={showToast}
        report={report}
      />
    </Fragment>
  );
}
