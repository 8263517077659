import React, { Fragment, useState } from "react";
import { Box, InputGroup, InputLeftAddon, Input, Flex } from "@chakra-ui/react";
import Form from "../ui/Form";
import InputComponent from "../ui/Input";
import Loader from "../ui/Loader";

export default function ProfileDetail({ user }) {
  const [progress, setProgress] = useState(0);

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />

      <Box w={{ lg: "40%", base: "100%", md: "100%" }} py="2rem">
        <Form>
          <Flex align="center" justify="space-between" gap="1rem">
            <InputComponent
              value={user?.first_name || ""}
              label="First Name"
              placeholder="First Name"
              isDisabled={true}
            />
            <InputComponent
              value={user?.last_name || ""}
              label="Last Name"
              placeholder="Last Name"
              isDisabled={true}
            />
          </Flex>
          <InputComponent
            value={user?.email || ""}
            label="Email"
            placeholder="Email"
            type="email"
            isDisabled={true}
          />
          <InputComponent
            value={user?.member_id || ""}
            label="Member ID"
            placeholder="Member ID"
            type="text"
            isDisabled={true}
          />

          <InputComponent
            value={user?.phone_number || ""}
            label="Phone Number"
            placeholder="Member ID"
            type="tel"
          />

          {user?.user_role?.toLowerCase() === "member" ? (
            <Flex align="center" gap="1rem">
              <InputComponent
                value={user?.department || ""}
                label="Department"
                placeholder="Department"
                isDisabled={true}
              />
              <InputComponent
                value={user?.directorate || ""}
                label="Directorate"
                placeholder="Directorate"
                isDisabled={true}
              />
            </Flex>
          ) : (
            <InputComponent
              value={user?.directorate}
              label="Directorate"
              placeholder="Directorate"
              isDisabled={true}
            />
          )}
        </Form>
      </Box>
    </Fragment>
  );
}
