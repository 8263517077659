import React from "react";
import ReusableTitle from "../../components/ui/ReusableTitle";

export default function SubmissionTitle() {
  return (
    <ReusableTitle
      text1="NAME"
      text2="TIME / DATE"
      text3="DEPARTMENT"
      text4="STATUS"
      text5="DESIGNATION"
    />
  );
}
