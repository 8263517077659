import React from "react";
import { Textarea, FormLabel, Flex } from "@chakra-ui/react";
import ReusableReplyCommentBtn from "./ReusableReplyCommentBtn";

export default function ReusableTextarea({
  value,
  onChange,
  label,
  register,
  visible,
  onClick,
  readOnly,
  bg,
}) {
  return (
    <div className="input-container-full">
      <Flex w="100%" align="center" justify="space-between">
        <FormLabel
          style={{ textTransform: "uppercase" }}
          fontSize="14px"
          fontWeight="normal"
          h="20px"
          color="#515262"
        >
          {label}
        </FormLabel>
        {visible && (
          <ReusableReplyCommentBtn onClick={onClick} title="+ ADD COMMENT" />
        )}
      </Flex>
      <Textarea
        size="md"
        variant="outline"
        border="1px solid #ccc"
        focusBorderColor="#481053"
        borderRadius="14px"
        placeholder="Enter Text Here"
        value={value}
        onChange={onChange}
        className="input"
        {...register}
        bg={bg ? "white" : "white"}
        readOnly={readOnly ? false : true}
      />
    </div>
  );
}
