import React, { useEffect, useState } from "react";
import WhiteBG from "../../components/ui/WhiteBG";
import DetailHeader from "../../components/ui/DetailHeader";
import { Box, Flex } from "@chakra-ui/react";
import DirectorateDetail from "./DirectorateDetail";
import { viewReports } from "../../util/http";
import { useParams } from "react-router-dom";

export default function DirectorateReportDetailContainer({ setProgress }) {
  const token = localStorage.getItem("token");
  const params = useParams();

  const [report, setReport] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await viewReports(token, params.id);
        setProgress(80);
        setProgress(100);
        setReport(response.report);
      } catch (error) {
        setProgress(100);
        // console.log(error);
      }
    }
    fetchData();
  }, [token, params.id]);

  return (
    <Box w="100%" px="10" mt="1.5rem">
      <WhiteBG>
        <Flex direction="column" gap="2rem">
          <DetailHeader text="Departmental Assessment & Service Details Form" />
          <DirectorateDetail report={report && report[0]} />
        </Flex>
      </WhiteBG>
    </Box>
  );
}
