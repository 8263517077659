import React from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Box,
} from "@chakra-ui/react";
import WhiteBG from "../../components/ui/WhiteBG";
import ProfileDetail from "../../components/profile/ProfileDetail";
import Security from "../../components/profile/Security";

export default function ReusableProfileContainer({ user }) {
  return (
    <Box
      w="100%"
      px={{ lg: "2.5rem", base: "0.5rem", md: "0.5rem" }}
      height="700px"
      mt="1.5rem"
    >
      <WhiteBG>
        <Tabs variant="unstyled">
          <TabList>
            <Tab
              fontSize={{ base: "12px", md: "12px", lg: "16px" }}
              px={{ lg: "2.5rem", base: "0.5rem", md: "0.5rem" }}
              color="brand.900"
              fontWeight="semibold"
            >
              PROFILE DETAILS
            </Tab>
            <Tab
              fontSize={{ base: "12px", md: "12px", lg: "16px" }}
              px={{ lg: "2.5rem", base: "0.5rem", md: "0.5rem" }}
              color="brand.900"
              fontWeight="semibold"
            >
              SECURITY
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="brand.900"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel>
              <ProfileDetail user={user} />
            </TabPanel>
            <TabPanel>
              <Security user={user} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </WhiteBG>
    </Box>
  );
}
