import React, { useState } from "react";
import {
  Flex,
  Text,
  Heading,
  Button,
  useToast,
  Box,
  Container,
} from "@chakra-ui/react";
import ReusableAddComment from "../ui/ReusableAddComment";
import { addComment } from "../../util/http";

export default function Comments({ report, user }) {
  const token = localStorage.getItem("token");
  const [reply, setReply] = useState("");
  const [loading, setLoading] = useState(false);

  const toast = useToast();



  function showToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status,
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  }

  async function addCommentHandler() {
    const data = {
      type: user?.user_role,
      report_id: report?.id,
      body: reply,
      parent_id: report?.comments[0]?.id,
    };
    try {
      setLoading(true);
      const response = await addComment(token, data);
      setLoading(false);
      showToast("Reply added", "", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      setLoading(false);
      showToast(
        error?.response?.data.message || "something went wrong",
        "",
        "error"
      );
      // console.log(error);
    }
  }

  return (
    <Flex
      borderRadius="0.75rem"
      align="center"
      maxHeight="90vh"
      justify="end"
      w="100%"
    >
      <Flex
        width="100%"
        gap="1rem"
        align="center"
        justify="end"
        direction="column"
        // bg="#D7D7D7"
        bg="white"
        p="1rem"
        // overflow="scroll"
      >
        <Heading
          fontSize="20px"
          fontWeight="medium"
          color="black"
          textAlign="center"
          as="h2"
          p="1rem"
          borderBottom="1px solid #ccc"
          style={{ textTransform: "capitalize" }}
        >
          Comment: {report?.comments[0]?.body}
        </Heading>
        <Flex
          w="100%"
          align="center"
          direction="column"
          gap="1rem"
          px="1rem"
          height="400px"
          overflow="scroll"
        >
          {report?.comments[0]?.replies?.map((reply) => {
            const replyTime = new Date(reply.created_at);
            const hour = replyTime.getHours();
            const minute = replyTime.getMinutes();
            return (
              <Flex
                width="100%"
                align="center"
                px="1rem"
                py="0.5rem"
                borderBottom="2px solid #fff"
                justify={
                  reply?.user?.user_role?.toLowerCase() === "member"
                    ? "start"
                    : "end"
                }
              >
                <Text
                  px="1rem"
                  maxWidth="800px"
                  py="0.5rem"
                  bg={
                    reply?.user?.user_role?.toLowerCase() === "member"
                      ? "brand.900"
                      : "#C6A533"
                  }
                  color={
                    reply?.user?.user_role?.toLowerCase() === "member"
                      ? "white"
                      : "white"
                  }
                  fontWeight="medium"
                  fontSize="14px"
                  borderRadius="0.75rem"
                  style={{ textTransform: "capitalize" }}
                >
                  {reply.body}
                  <Flex align="center" gap="1rem" justify="space-between">
                    <Text mt="0.3rem" fontSize="8px">{`${hour
                      .toString()
                      .padStart(2, 0)}:${minute
                      .toString()
                      .padStart(2, 0)}`}</Text>
                    {reply.type !== "member" && (
                      <Text fontSize="8px">
                        {report?.comments[0]?.user?.first_name} |{" "}
                        {reply?.user.user_role}
                      </Text>
                    )}
                  </Flex>
                </Text>
              </Flex>
            );
          })}
        </Flex>

        <ReusableAddComment
          value={reply}
          onChange={(e) => setReply(e.target.value)}
        />

        <Flex align="center" justify="end" gap="1rem">
          <Button
            isDisabled={
              report?.status?.toLowerCase() === "approved" &&
              user?.user_role?.toLowerCase() === "member"
            }
            variant="solid"
            color="white"
            bg="brand.900"
            isLoading={loading}
            loadingText="submitting"
            onClick={addCommentHandler}
          >
            Submit Comment
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
