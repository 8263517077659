import React, { Fragment } from "react";
import notification from "../../images/notification.png";
import drop from "../../images/arrow.png";
import userImg from "../../images/user1.png";
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  Avatar,
  Text,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import ImageComponent from "../ui/Image";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../util/http";
import { useContext } from "react";
import { AuthContext } from "../../util/authContext";
import { AiOutlineMenu } from "react-icons/ai";
import MobileHeader from "./MobileHeader";

export default function Header({ title, user, path }) {
  const navigate = useNavigate();
  const ctx = useContext(AuthContext);
  const token = localStorage.getItem("token");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const fullname = `${user?.first_name} ${user?.last_name}`;

  async function logoutHandler() {
    try {
      await logout(token);
      ctx.logout();
      navigate("/login");
    } catch (error) {
      navigate("/login");
      // console.log(error);
    }
  }

  return (
    <Fragment>
      <Flex
        w="100%"
        borderBottom="2px solid #ccc"
        px="1rem"
        py="1rem"
        alignItems="center"
        justify="space-between"
      >
        <Text
          color="#3F434A"
          fontSize="18px"
          fontWeight="medium"
          px="1.5rem"
          display={{ base: "none", md: "none", lg: "block" }}
          // className="text-[#3F434A] capitalize text-[18px] font-medium px-6"
        >
          {title ? title : ""}
        </Text>
        <Button
          display={{ base: "block", md: "block", lg: "none" }}
          onClick={onOpen}
          variant="ghost"
        >
          <AiOutlineMenu />
        </Button>
        <Flex alignItems="center" gap="1rem">
          <Box
            display={{ base: "none", md: "none", lg: "block" }}
            w="16px"
            className="w-[16px]"
          >
            <ImageComponent
              src={notification}
              alt="notification"
              height="16.87"
            />
          </Box>
          <span className="hidden lg:block h-[30px] w-[2px] bg-[#ccc]"></span>
          <Flex alignItems="center" gap="0.5rem" cursor="pointer">
            <Box w="40px">
              <Avatar
                size={{ base: "sm", md: "sm", lg: "md" }}
                name={fullname}
                src={user?.picture}
              />
            </Box>
            <Menu>
              <MenuButton
                bg="transparent"
                color="brand.900"
                as={Button}
                rightIcon={<ChevronDownIcon />}
                _hover={{ bg: "transparent" }}
                _expanded={{ bg: "transparent" }}
                _focus={{ bg: "transparent" }}
              >
                <Text
                  style={{ textTransform: "capitalize" }}
                  fontSize={{ base: "8px", md: "14px", lg: "14px" }}
                  fontWeight="medium"
                  textAlign="start"
                >
                  {String(
                    `${user?.first_name ? user.first_name : ""} ${
                      user?.last_name ? user.last_name : ""
                    }`
                  )
                    .slice(0, 9)
                    .padEnd(12, ".")}
                </Text>
                <Text
                  style={{ textTransform: "capitalize" }}
                  textAlign="start"
                  fontSize="10px"
                  fontWeight="light"
                >
                  {user?.user_role ? user.user_role : ""}
                </Text>
              </MenuButton>
              <MenuList>
                <MenuItem
                  _focus={{ bg: "#fff" }}
                  _hover={{ bg: "#fff" }}
                  _expanded={{ bg: "#fff" }}
                >
                  <Link to={`/profile`}>View Profile</Link>
                </MenuItem>
                <MenuItem
                  _hover={{ bg: "#fff" }}
                  _expanded={{ bg: "#fff" }}
                  _focus={{ bg: "#fff" }}
                  onClick={logoutHandler}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
      <MobileHeader
        // dashboard={dashboard}
        // report={report}
        user={user}
        // show={show}
        logoutHandler={logoutHandler}
        isOpen={isOpen}
        onClose={onClose}
        // profile={profile}
      />
    </Fragment>
  );
}
