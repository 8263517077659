import React, { useContext, useState } from "react";
import { Box, Flex, useDisclosure, useToast } from "@chakra-ui/react";
import WhiteBG from "../../../components/ui/WhiteBG";
import InputComponent from "../../../components/ui/Input";
import ButtonComponent from "../../../components/ui/Button";
import EditUser from "../EditUser";
import { AuthContext } from "../../../util/authContext";
import { deleteUser } from "../../../util/http";
import { useNavigate } from "react-router-dom";

export default function UserDetailContainer({ user }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const ctx = useContext(AuthContext);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const toast = useToast();

  function showToast(title, description, status) {
    toast({
      title: title,
      description: description,
      status,
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  }

  async function deleteHandler() {
    try {
      setLoading(true);
      const response = await deleteUser(token, user?.id);
      showToast(`${user?.first_name} successfully deleted`, "", "success");
      setLoading(false);

      navigate("/user");
    } catch (error) {
      setLoading(false);
      showToast(
        error?.response?.data.message || "something went wrong",
        "",
        "error"
      );
      // console.log(error);
    }
  }

  return (
    <Box w="100%" px="10" mt="1.5rem">
      <WhiteBG>
        <Flex w="60%" align="center" direction="column" gap="2rem">
          <Flex w="100%" align="center" gap="1rem" justify="space-between">
            <InputComponent label="First Name" value={user?.first_name || ""} />
            <InputComponent label="Last Name" value={user?.last_name || ""} />
          </Flex>

          <InputComponent label="Email" value={user?.email || ""} />
          <InputComponent
            label="Phone Number"
            value={user?.phone_number || ""}
          />

          <InputComponent label="Designation" value={user?.designation || ""} />
          <InputComponent label="Directorate" value={user?.directorate || ""} />

          <InputComponent label="Role" value={user?.user_role || ""} />
          <InputComponent label="Member ID" value={user?.member_id || ""} />
        </Flex>
        <Flex mt="3rem" gap="1rem" w="100%" align="center" justify="end">
          <ButtonComponent
            color="error.900"
            variant="ghost"
            title="Delete User"
            bg="transparent"
            onClick={deleteHandler}
            isLoading={loading}
            loadText="Deleting"
          />

          <ButtonComponent onClick={onOpen} title="Edit User" />
        </Flex>
      </WhiteBG>
      <EditUser isOpen={isOpen} onClose={onClose} user={user} />
    </Box>
  );
}
