import React, { Fragment, useEffect, useState } from "react";
import DashboardReusable from "../../components/ui/DashboardReusable";
import ReusableSubHeader from "../../components/ui/ReusableSubheader";
import ProfileContainer from "../../components/profile/ProfileContainer";
import { getUsers } from "../../util/http";
import Loader from "../../components/ui/Loader";

export default function Profile() {
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("memberID");
  const [user, setUser] = useState("");
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getUsers(token, id);
        setProgress(80);
        setProgress(100);
        setUser(response.user[0]);
      } catch (error) {
        setProgress(100);
        // console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Fragment>
      <Loader progress={progress} setProgress={setProgress} />
      <DashboardReusable show={true}>
        <ReusableSubHeader text="Profile" />
        <ProfileContainer user={user} />
      </DashboardReusable>
    </Fragment>
  );
}
