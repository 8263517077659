import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import NewPasswordPage from "./pages/NewPasswordPage";
import DirectorateDashboardPage from "./pages/DirectorateDashboardPage";
import DirectorateReportPage from "./pages/DirectorateReportPage";
import DirectorateProfilePage from "./pages/DirectorateProfilePage";
import DirectorateReportDetailPage from "./pages/DirectorateReportDetailPage";
import DirectorateUserPage from "./pages/DirectorateUserPage";
import NotFound from "./pages/NotFoundPage";
import ConfirmReset from "./pages/ConfirmReset";
import UserDetailPage from "./pages/UserDetailPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<DirectorateDashboardPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/new-password" element={<NewPasswordPage />} />
      <Route path="/confirm-email" element={<ConfirmReset />} />
      <Route path="/report" element={<DirectorateReportPage />} />
      <Route path="/report/:id" element={<DirectorateReportDetailPage />} />
      <Route path="/profile" element={<DirectorateProfilePage />} />
      <Route path="/user" element={<DirectorateUserPage />} />
      <Route path="/user/:id" element={<UserDetailPage />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
