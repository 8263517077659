import React from "react";
import { Flex } from "@chakra-ui/react";
import SubmissionTitleText from "../ui/SubmissionTitleText";

export default function ReusableTitle({ text1, text2, text3, text4, text5 }) {
  return (
    <Flex
      justify="space-between"
      align="center"
      // bg="brand.900"
      className="w-full flex items-center justify-between"
    >
      <SubmissionTitleText text={text1} />
      <SubmissionTitleText
        display={{ base: "none", md: "none", lg: "block" }}
        text={text2}
      />
      <SubmissionTitleText text={text3} />
      <SubmissionTitleText text={text4} />
      <SubmissionTitleText text={text5} />
    </Flex>
  );
}
