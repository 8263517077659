import React from "react";
import { Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";

export default function DoubleInputState({
  label1,
  value1,
  onChange1,
  placeholder,
  placeholder2,
  label2,
  value2,
  onChange2,
  readOnly,
}) {
  return (
    <Flex align="center" justify="space-between" gap="2rem">
      <FormControl>
        <FormLabel
          fontSize="14px"
          // autoCapitalize="on"
          fontWeight="normal"
          h="20px"
          color="#515262"
          style={{ textTransform: "uppercase" }}
        >
          {label1}
        </FormLabel>
        <Input
          size="md"
          variant="outline"
          border="1px solid #ccc"
          focusBorderColor="#481053"
          borderRadius="14px"
          type="text"
          placeholder={placeholder}
          value={value1}
          onChange={onChange1}
          style={{ textTransform: "capitalize" }}
          readOnly={readOnly ? false : true}
        />
      </FormControl>
      <FormControl>
        <FormLabel
          autoCapitalize="true"
          fontSize="14px"
          fontWeight="normal"
          h="20px"
          color="#515262"
        >
          {label2}
        </FormLabel>
        <Input
          size="md"
          variant="outline"
          border="1px solid #ccc"
          focusBorderColor="#481053"
          borderRadius="14px"
          type="text"
          placeholder={placeholder2 ? placeholder2 : placeholder}
          value={value2}
          onChange={onChange2}
          readOnly={readOnly ? false : true}
        />
      </FormControl>
    </Flex>
  );
}

// outline-none p-2 rounded-xl w-full border;
